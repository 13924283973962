<template>
  <v-container class="py-6">
    <v-row>
      <v-col cols="12">
        <div class="box-wrapper">
          <div
            class="box-overlay"
            :class="{ open: isSidebar }"
            @click="isSidebar = !isSidebar"
          ></div>
          <div class="box-sidebar pb-3 shadow-sm" :class="{ open: isSidebar }">
            <DashbordSidebarTwo />
          </div>
          <div class="box-content">
            <div class="d-flex justify-end pa-2 d-block d-md-none">
              <v-btn icon @click="isSidebar = !isSidebar">
                <v-icon dark> mdi-format-list-bulleted-square </v-icon>
              </v-btn>
            </div>
            <div class="box-container">
              <div class="d-flex align-center mb-5">
                <v-avatar tile size="25" class="me-3">
                  <img src="@/assets/images/icons/bag_filled.svg" alt="" />
                </v-avatar>
                <h2 class="mb-0">Meus Pedidos</h2>
              </div>

              <v-card-text>
                <v-col
                  cols="12"
                  sm="12"
                  md="12"
                  xs="12"
                  v-if="!overlay && pedidos.length > 0"
                >
                  <v-card class="pa-2 ml-2 mr-2" outlined>
                    <!-- <v-card-title
                      class="headline mx-4 primary--text text-uppercase"
                    >
                      <v-breadcrumbs
                        class="text-uppercase font-weight-medium"
                        :items="items"
                      />
                    </v-card-title> -->
                    <v-divider />

                    <!-- <v-card outlined>
                                <v-row>
                                    <v-col cols="12" class="ml-2 mt-4">
                                        <h5>FILTROS COMBINADOS</h5></v-col
                                    >
                                    <v-divider />
                                </v-row>
                                <v-row class="mt-2 mx-2">
                                    <v-col
                                        cols="12"
                                        sm="12"
                                        md="6"
                                        class="text-uppercase"
                                    >
                                        <v-autocomplete
                                            dense
                                            item-value="id"
                                            item-text="designacao"
                                            :items="estadosFatura"
                                            label="Estados da Fatura"
                                            outlined
                                        />
                                    </v-col>
                                </v-row>
                            </v-card> -->

                    <v-card-title primary-title color="primary">
                      Meus Pedidos ({{ pedidos.length }})

                      <v-divider class="mx-4" inset vertical />
                      <v-text-field
                        outlined
                        dense
                        append-icon="search"
                        rounded
                        v-model="search"
                        placeholder="Procurar por ..."
                      />
                      <v-spacer />
                    </v-card-title>

                    <v-card-text>
                      <v-data-table
                        :headers="headers"
                        :items="pedidos"
                        :search="search"
                        :sort-by.sync="sortBy"
                        :sort-desc.sync="sortDesc"
                      >
                        <template v-slot:[`item.valor_a_pagar`]="{ item }">
                          <span>
                            {{ formatPrice(item.valor_a_pagar) }}
                            AKZ</span
                          >
                        </template>
                        <template
                          v-slot:[`item.estado_factura.designacao`]="{ item }"
                        >
                          <v-chip
                            class="ma-2 text-uppercase"
                            color="success"
                            v-if="item.estado_factura.id == 1"
                          >
                            <v-icon left> done </v-icon>
                            {{ item.estado_factura.designacao }}
                          </v-chip>
                          <v-chip
                            class="ma-2 text-uppercase"
                            color="warning"
                            v-else
                          >
                            <v-icon left> mdi-car </v-icon>
                            {{ item.estado_factura.designacao }}
                          </v-chip>
                        </template>
                        <template v-slot:[`item.actions`]="{ item }">
                          <v-icon
                            color="success"
                            class="mr-2"
                            title="Detalhes da Fatura"
                            btn
                          >
                            mdi-eye
                          </v-icon>
                          <v-icon
                            color="primary"
                            class="mr-2"
                            title="Imprimir Fatura"
                          >
                            print
                          </v-icon>
                        </template>
                      </v-data-table>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-card-text>
            </div>
          </div>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import DashbordSidebarTwo from "@/components/DashboardSidebarTwo.vue";
export default {
  components: {
    DashbordSidebarTwo,
  },
  data() {
    return {
      talPedidos: 0,
      query: {},
      pedidos: [
        {
          id: 1,
          faturaReference: "12FGH3",
          created_at: "06-04-2023",
          estado_factura: { id: 1, designacao: "ENTREGUE" },
          valor_a_pagar: "955",
        },
        {
          id: 2,
          faturaReference: "21FYH3",
          created_at: "13-04-2023",
          estado_factura: { id: 2, designacao: "A CAMINHO" },
          valor_a_pagar: "55459",
        },
      ],
      headers: [
        {
          text: "Referência",
          align: "start",
          sortable: true,
          value: "faturaReference",
        },
        { text: "Valor Pago", value: "valor_a_pagar" },
        { text: "Estado", value: "estado_factura.designacao" },
        { text: "Data", value: "created_at" },
        { text: "Acções", value: "actions", sortable: false },
      ],
      overlay: false,
      factura: {},
      factura_detalhes: {},
      pagamentos_facturas: [],
      facturasItems: [],
      search: "",
      loading: false,
      sortBy: "created_at",
      sortDesc: true,
      pagamentos: [],

      overlay: false,
      isSidebar: false,
      page: 1,
      itemsPerPage: 5,
      items: [
        0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 14, 15, 16, 17, 18, 19, 20,
      ],

      range: [0, 100, 500, 1000],
      itemsTwo: ["Low to High", "High to Low", "Date"],
    };
  },
  computed: {
    numberOfPages() {
      return Math.ceil(this.items.length / this.itemsPerPage);
    },
  },
  created() {
    window.addEventListener("scroll", this.handleScroll);
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    nextPage() {
      if (this.page + 1 <= this.numberOfPages) this.page += 1;
    },
    formerPage() {
      if (this.page - 1 >= 1) this.page -= 1;
    },
    season(val) {
      return this.range[val];
    },
  },
};
</script>
